<template>
  <a-layout :style="{ 'min-height': '100%' }">
    <a-layout-sider :collapsed="collapsed" collapsible :theme="isDark ? 'dark' : 'light'" class="sider">
      <div class="sider-header">
        <img src="@/assets/images/admin-logo-white.png" alt class="sider-logo" v-if="isDark"/>
        <img src="@/assets/images/admin-logo.png" alt class="sider-logo" v-else/>
        <h1 class="sider-header-title" v-show="!collapsed" :style="{color: !isDark ? '#001529' : '#fff'}">宝秤管理后台</h1>
      </div>
      <!-- theme="dark" -->
      <a-menu :default-selected-keys="[$route.name]" mode="inline" :inline-collapsed="collapsed" :theme="isDark ? 'dark' : 'light'">
        <a-menu-item v-for="item in menu" :key="item.router.name" @click="clickMenu(item)">
          <a-icon :type="item.icon" />
          <span>{{ item.name }}</span>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout-content :style="{ marginLeft: collapsed ? '80px' : '200px' }" :theme="isDark ? 'dark' : 'light'">
      <a-layout-header class="layout-header" :style="{ left: collapsed ? '80px' : '200px','background-color': isDark ? '#001529' : '#fff' ,color: !isDark ? '#001529' : '#fff'}">
        <div class="header">
          <a-icon :type="collapsed ? 'menu-unfold' : 'menu-fold'" @click="collapsed = !collapsed" />
          <div class="header-right">
            <a-dropdown placement="bottomCenter">
              <a-avatar :size="30" icon="user" />
              <a-menu slot="overlay">
                <a-menu-item @click="logout">退出登录</a-menu-item>
              </a-menu>
            </a-dropdown>
            <div class="header-right-icon"  @click="changeTheme">
              <a-icon type="bulb" />
            </div>
          </div>
        </div>
      </a-layout-header>
      <a-layout-content :style="{marginTop: '64px'}">
        <router-view></router-view>
      </a-layout-content>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { Layout, Menu, Avatar, Dropdown } from 'ant-design-vue'
import menu from '@/config/menu'
import { removeUserInfo } from '@/utils/auth'

export default {
  components: {
    ALayoutHeader: Layout.Header,
    ALayoutSider: Layout.Sider,
    ALayoutContent: Layout.Content,
    ALayout: Layout,
    [Menu.name]: Menu,
    [Menu.Item.name]: Menu.Item,
    [Avatar.name]: Avatar,
    [Dropdown.name]: Dropdown
  },
  data () {
    return {
      collapsed: false,
      isDark: false,
      menu: menu
    }
  },
  methods: {
    clickMenu (item) {
      this.$router.push(item.router)
    },
    logout () {
      removeUserInfo()
      this.$router.replace({
        name: 'Login'
      })
    },
    changeTheme () {
      this.isDark = !this.isDark
    }
  }
}
</script>

<style lang="scss" scoped>
.sider {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  z-index: 9;
}
.sider-header {
  height: 64px;
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  .sider-header-title {
    padding-left: 5px;
    letter-spacing: 0.15em;
    font-weight: bold;
    font-size: 14px;
  }
  .sider-logo {
    width: 40px;
  }
}

.layout-header {
  position: fixed;
  z-index: 8;
  right: 0;
  background: #fff;
  padding: 0 20px;
   box-shadow: 0px 4px 20px -5px rgba(0, 0, 0, 0.1),
            0px 4px 20px -5px rgba(0, 0, 0, 0.1);
}

.header {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  .header-right {
    display: flex;
    align-items: center;

    .header-right-icon {
      padding: 0 20px;
    }
  }
}
</style>
