export default [
  {
    name: '广告设置',
    icon: 'fire',
    router: {
      name: 'Ad'
    }
  },
  {
    name: '页面管理',
    icon: 'menu',
    router: {
      name: 'Menu'
    }
  },
  {
    name: '新闻资讯',
    icon: 'book',
    router: {
      name: 'Articles'
    }
  },
  {
    name: '内容发布',
    icon: 'copy',
    router: {
      name: 'Content'
    }
  },
  {
    name: '招聘管理',
    icon: 'team',
    router: {
      name: 'Recruit'
    }
  },
  {
    name: '预约顾问',
    icon: 'team',
    router: {
      name: 'Consultant'
    }
  }
]
